// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 5,
		title: 'Darydar',
		category: 'Web Application',
		img: require('@/assets/images/darydar.webp'),
		link:'https://darydar.tn'
	},
	{
		id: 1,
		title: 'FellnFill',
		category: 'Web Application',
		img: require('@/assets/images/Shop.webp'),
		link:'https://www.feelnfill-preprod.tundev.tn/'
	},
	{
		id: 2,
		title: 'Intone Digitals',
		category: 'UI/UX Design',
		img: require('@/assets/images/intone-project.webp'),
		link:'https://intone.tundev.tn/'
	},
	{
		id: 3,
		title: 'Bransley Restaurant',
		category: 'Web Application',
		img: require('@/assets/images/restaurant-project.webp'),
		link:'https://bransleyrestaurant.tundev.tn/home'
	},
	{
		id: 4,
		title: 'Pneu service',
		category: 'Web Application',
		img: require('@/assets/images/pneu-service.webp'),
		link:'https://pneu-service.tn'
	},
	{
		id: 5,
		title: 'Hexamobile',
		category: 'Web Application',
		img: require('@/assets/images/hexamobile.webp'),
		link:'/files/home-hexamobile.pdf'
	},
	{
		id: 6,
		title: 'LCDT',
		category: 'Web Application',
		img: require('@/assets/images/lcdt.png'),
		link:'https://lcdt-stage.vpc-direct-service.com'
	},
	{
		id: 7,
		title: 'Chamas Tacos',
		category: 'Web Application',
		img: require('@/assets/images/chamas-tacos.png'),
		link:'https://chamas-tacos.com/'
	},


];

export default projects;